import Link from 'next/link';
import styled from 'styled-components';

import { colors, fontWeights } from '@hultafors/eripiowear/helpers';

interface ProductPlugStyledProps {
  $hasImage?: boolean;
}

export const ProductPlugStyled = styled.article`
  background: ${colors.gray5};
  position: relative;
`;

export const Inner = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const TextWrapper = styled.div`
  position: absolute;
  top: 38%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-block-start: 1rem;
  }
`;

export const ProductPlugTitle = styled.span<ProductPlugStyledProps>`
  line-height: 28px;
  font-weight: ${fontWeights.bold};
  text-align: center;
  font-size: 20px;
  color: ${({ $hasImage }) => ($hasImage ? colors.white : colors.solidBlack)};
  text-shadow: 0 0 5px
    ${({ $hasImage }) => ($hasImage ? colors.solidBlack : colors.gray5)};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const BackgroundColors = styled.div`
  position: absolute;
  inset: 0;

  &::after {
    content: '';
    block-size: 100%;
    background-image: url('/svg/color-puff-bg.svg');
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
`;
