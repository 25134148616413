import dynamic from 'next/dynamic';

import { minWidth } from '@hultafors/shared/style';

import { ImageLoadFragment } from '@hultafors/eripiowear/types';

import {
  BackgroundColors,
  Inner,
  ProductPlugStyled,
  ProductPlugTitle,
  TextWrapper,
} from './teaser-simple.styled';

const DatoImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((module) => module.DatoImage),
  { ssr: false },
);

export interface TeaserSimpleProps {
  title?: string;
  image?: ImageLoadFragment | null;
  color?: string;
  url?: string;
}

export const TeaserSimple: React.FC<TeaserSimpleProps> = ({
  url = '',
  image,
  title = '',
}) => {
  const sizes = [
    minWidth.desktopLarge`338px`,
    minWidth.desktopMedium`25vw`,
    minWidth.largeMobile`50vw`,
    '100vw',
  ].join(', ');
  return (
    <ProductPlugStyled>
      <Inner href={url}>
        {image?.url ? (
          <DatoImage image={image} aspectRatio={3 / 4} sizes={sizes} />
        ) : (
          <BackgroundColors />
        )}
        <TextWrapper>
          <ProductPlugTitle $hasImage={!!image?.url}>{title}</ProductPlugTitle>
        </TextWrapper>
      </Inner>
    </ProductPlugStyled>
  );
};
